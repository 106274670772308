<template>
  <v-container
  >
    <v-card
        tile
        flat
    >
      <v-card-title
          class="pa-0 ma-0"
      >
        {{ title }}
      </v-card-title>
      <v-card-text
          class="d-flex flex-wrap ma-0 pa-0"
      >

        <template
            v-for="(image, index) in media">
          <v-img
              class="ma-1 pa-0"
              :key="index"
              :max-width="image_size"
              :height="image_size"
              :width="image_size"
              :aspect-ratio="1"
              style="cursor: pointer"
              @click="setIndex(index)"
              :src="image.thumb"
          />
        </template>
      </v-card-text>
    </v-card>

    <CoolLightBox
        :items="media"
        :index="index"
        @close="index = null">
    </CoolLightBox>
  </v-container>

</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'

export default {
  name: "PhotoAlbum",
  components: {
    CoolLightBox,
  },
  props: ['title', 'media'],
  data() {
    return {
      index: null,
    }
  },
  methods: {
    setIndex(index) {
      this.index = index
    }
  },
  computed: {
    image_size() {

      if (this.$vuetify.breakpoint.lgAndUp) {
        return 150
      }
      if (this.$vuetify.breakpoint.smAndUp) {
        return 100
      }
      return 50
    },
  }
}
</script>

<style scoped>

</style>