const media = [
    {
        title: 'Granit',
        assets: [
            {
                src: require('./Granit_files/036_35A/036_35A.jpg'),
                thumb: require('./Granit_files/036_35A/thumb.jpg'),
            },
            {
                src: require('./Granit_files/200mm zoom 079/200mm zoom 079.jpg'),
                thumb: require('./Granit_files/200mm zoom 079/thumb.jpg'),
            },
            {
                src: require('./Granit_files/2008-7-10 015/2008-7-10 015.jpg'),
                thumb: require('./Granit_files/2008-7-10 015/thumb.jpg'),
            },
            {
                src: require('./Granit_files/2008-26-09 skulp maleri 004/2008-26-09 skulp maleri 004.jpg'),
                thumb: require('./Granit_files/2008-26-09 skulp maleri 004/thumb.jpg'),
            },
            {
                src: require('./Granit_files/DSC00369/DSC00369.jpg'),
                thumb: require('./Granit_files/DSC00369/thumb.jpg'),
            },
            {
                src: require('./Granit_files/DSC01190/DSC01190.jpg'),
                thumb: require('./Granit_files/DSC01190/thumb.jpg'),
            },
            {
                src: require('./Granit_files/Kopi af DSC00254/Kopi af DSC00254.jpg'),
                thumb: require('./Granit_files/Kopi af DSC00254/thumb.jpg'),
            },
            {
                src: require('./Granit_files/naturskulp med buler 005/naturskulp med buler 005.jpg'),
                thumb: require('./Granit_files/naturskulp med buler 005/thumb.jpg'),
            },
            {
                src: require('./Granit_files/Rotation of besgskatalog4/Rotation of besgskatalog4.jpg'),
                thumb: require('./Granit_files/Rotation of besgskatalog4/thumb.jpg'),
            },
        ]
    },
    {
        title: "Bronze",
        assets: [

            {
                src: require('./Bronze_files/11-11-08 udstilling skabelsbjergaard 028/11-11-08 udstilling skabelsbjergaard 028.jpg'),
                thumb: require('./Bronze_files/11-11-08 udstilling skabelsbjergaard 028/thumb.jpg'),
            },
            {
                src: require('./Bronze_files/13-11-08 udstilling skabelsbjergaard 007/13-11-08 udstilling skabelsbjergaard 007.jpg'),
                thumb: require('./Bronze_files/13-11-08 udstilling skabelsbjergaard 007/thumb.jpg'),
            },
            {
                src: require('./Bronze_files/18-11-08 udstilling skabelsbjergaard 012/18-11-08 udstilling skabelsbjergaard 012.jpg'),
                thumb: require('./Bronze_files/18-11-08 udstilling skabelsbjergaard 012/thumb.jpg'),
            },
            {
                src: require('./Bronze_files/Billede 005_1/Billede 005_1.jpg'),
                thumb: require('./Bronze_files/Billede 005_1/thumb.jpg'),
            },
            {
                src: require('./Bronze_files/IMG_0017/IMG_0017.jpg'),
                thumb: require('./Bronze_files/IMG_0017/thumb.jpg'),
            },
            {
                src: require('./Bronze_files/Kopi af DSC01168/Kopi af DSC01168.jpg'),
                thumb: require('./Bronze_files/Kopi af DSC01168/thumb.jpg'),
            }
        ]
    },
    {
        title: "Maleri",
        assets: [

            {
                src: require('./Malerier_files/16-11-08 udstilling skabelsbjergaard 021/16-11-08 udstilling skabelsbjergaard 021.jpg'),
                thumb: require('./Malerier_files/16-11-08 udstilling skabelsbjergaard 021/thumb.jpg')
            },
            {
                src: require('./Malerier_files/16-11-08 udstilling skabelsbjergaard 023/16-11-08 udstilling skabelsbjergaard 023.jpg'),
                thumb: require('./Malerier_files/16-11-08 udstilling skabelsbjergaard 023/thumb.jpg')
            },
            {
                src: require('./Malerier_files/16-11-08 udstilling skabelsbjergaard 032/16-11-08 udstilling skabelsbjergaard 032.jpg'),
                thumb: require('./Malerier_files/16-11-08 udstilling skabelsbjergaard 032/thumb.jpg')
            },
            {
                src: require('./Malerier_files/17-03-09 frankri og billeder 012/17-03-09 frankri og billeder 012.jpg'),
                thumb: require('./Malerier_files/17-03-09 frankri og billeder 012/thumb.jpg')
            },
            {
                src: require('./Malerier_files/28-02-09 malerier 005/28-02-09 malerier 005.jpg'),
                thumb: require('./Malerier_files/28-02-09 malerier 005/thumb.jpg')
            },
            {
                src: require('./Malerier_files/2008-26-09 skulp maleri 059/2008-26-09 skulp maleri 059.jpg'),
                thumb: require('./Malerier_files/2008-26-09 skulp maleri 059/thumb.jpg')
            },
            {
                src: require('./Malerier_files/2008-26-09 skulp maleri 071/2008-26-09 skulp maleri 071.jpg'),
                thumb: require('./Malerier_files/2008-26-09 skulp maleri 071/thumb.jpg')
            },
            {
                src: require('./Malerier_files/2008-28-08 skitser 003/2008-28-08 skitser 003.jpg'),
                thumb: require('./Malerier_files/2008-28-08 skitser 003/thumb.jpg')
            },
            {
                src: require('./Malerier_files/2009-9-01 skitser maleri 074/2009-9-01 skitser maleri 074.jpg'),
                thumb: require('./Malerier_files/2009-9-01 skitser maleri 074/thumb.jpg')
            },
        ]
    },
    {
        title: "Små figurer",
        assets: [
            {
                src: require('./Sma_figurer_files/3 200mm 002 405/3 200mm 002 405.jpg'),
                thumb: require('./Sma_figurer_files/3 200mm 002 405/thumb.jpg')
            },
            {
                src: require('./Sma_figurer_files/4 200mm 023/4 200mm 023.jpg'),
                thumb: require('./Sma_figurer_files/4 200mm 023/thumb.jpg')
            },
            {
                src: require('./Sma_figurer_files/200mm zoom 070/200mm zoom 070.jpg'),
                thumb: require('./Sma_figurer_files/200mm zoom 070/thumb.jpg')
            },
            {
                src: require('./Sma_figurer_files/havhingsten 01-07-07 082/havhingsten 01-07-07 082.jpg'),
                thumb: require('./Sma_figurer_files/havhingsten 01-07-07 082/thumb.jpg')
            },
            {
                src: require('./Sma_figurer_files/IMG_0013/IMG_0013.jpg'),
                thumb: require('./Sma_figurer_files/IMG_0013/thumb.jpg')
            },
            {
                src: require('./Sma_figurer_files/IMG_0029/IMG_0029.jpg'),
                thumb: require('./Sma_figurer_files/IMG_0029/thumb.jpg')
            },
            {
                src: require('./Sma_figurer_files/IMG_0037/IMG_0037.jpg'),
                thumb: require('./Sma_figurer_files/IMG_0037/thumb.jpg')
            },
            {
                src: require('./Sma_figurer_files/IMG_0042/IMG_0042.jpg'),
                thumb: require('./Sma_figurer_files/IMG_0042/thumb.jpg')
            },
            {
                src: require('./Sma_figurer_files/IMG_0083/IMG_0083.jpg'),
                thumb: require('./Sma_figurer_files/IMG_0083/thumb.jpg')
            },
            {
                src: require('./Sma_figurer_files/skulptur 2-12-09 004/skulptur 2-12-09 004.jpg'),
                thumb: require('./Sma_figurer_files/skulptur 2-12-09 004/thumb.jpg')
            },
            {
                src: require('./Sma_figurer_files/skulptur 2-12-09 007/skulptur 2-12-09 007.jpg'),
                thumb: require('./Sma_figurer_files/skulptur 2-12-09 007/thumb.jpg')
            },
            {
                src: require('./Sma_figurer_files/tyskland 2007 25-07-07 049/tyskland 2007 25-07-07 049.jpg'),
                thumb: require('./Sma_figurer_files/tyskland 2007 25-07-07 049/thumb.jpg')
            },
        ]
    },
    {
        title: "Tegning",
        assets: [
            {
                src: require('./Tegninger_files/2008-26-09 skulp maleri 064/2008-26-09 skulp maleri 064.jpg'),
                thumb: require('./Tegninger_files/2008-26-09 skulp maleri 064/thumb.jpg')
            },
            {
                src: require('./Tegninger_files/2008-26-09 skulp maleri 067/2008-26-09 skulp maleri 067.jpg'),
                thumb: require('./Tegninger_files/2008-26-09 skulp maleri 067/thumb.jpg')
            },
            {
                src: require('./Tegninger_files/2008-26-09 skulp maleri 068/2008-26-09 skulp maleri 068.jpg'),
                thumb: require('./Tegninger_files/2008-26-09 skulp maleri 068/thumb.jpg')
            },
            {
                src: require('./Tegninger_files/skulp og tegninger 2009 036/skulp og tegninger 2009 036.jpg'),
                thumb: require('./Tegninger_files/skulp og tegninger 2009 036/thumb.jpg')
            },
            {
                src: require('./Tegninger_files/skulp og tegninger 2009 038/skulp og tegninger 2009 038.jpg'),
                thumb: require('./Tegninger_files/skulp og tegninger 2009 038/thumb.jpg')
            },
            {
                src: require('./Tegninger_files/skulp og tegninger 2009 040/skulp og tegninger 2009 040.jpg'),
                thumb: require('./Tegninger_files/skulp og tegninger 2009 040/thumb.jpg')
            },
            {
                src: require('./Tegninger_files/skulp og tegninger 2009 041/skulp og tegninger 2009 041.jpg'),
                thumb: require('./Tegninger_files/skulp og tegninger 2009 041/thumb.jpg')
            },
            {
                src: require('./Tegninger_files/skulp og tegninger 2009 042/skulp og tegninger 2009 042.jpg'),
                thumb: require('./Tegninger_files/skulp og tegninger 2009 042/thumb.jpg')
            },
            {
                src: require('./Tegninger_files/skulp og tegninger 2009 043/skulp og tegninger 2009 043.jpg'),
                thumb: require('./Tegninger_files/skulp og tegninger 2009 043/thumb.jpg')
            },
            {
                src: require('./Tegninger_files/skulp og tegninger 2009 045/skulp og tegninger 2009 045.jpg'),
                thumb: require('./Tegninger_files/skulp og tegninger 2009 045/thumb.jpg')
            },
        ]
    },
    {
        title: "Parken",
        assets: [
            {
                src: require('./Parken_files/22-05-07 gravsten 013/22-05-07 gravsten 013.jpg'),
                thumb: require('./Parken_files/22-05-07 gravsten 013/thumb.jpg')
            },
            {
                src: require('./Parken_files/DSC01181/DSC01181.jpg'),
                thumb: require('./Parken_files/DSC01181/thumb.jpg')
            },
            {
                src: require('./Parken_files/DSC01196/DSC01196.jpg'),
                thumb: require('./Parken_files/DSC01196/thumb.jpg')
            },
            {
                src: require('./Parken_files/DSC01197/DSC01197.jpg'),
                thumb: require('./Parken_files/DSC01197/thumb.jpg')
            },
            {
                src: require('./Parken_files/prve 025/prve 025.jpg'),
                thumb: require('./Parken_files/prve 025/thumb.jpg')
            },
            {
                src: require('./Parken_files/skulp i aftenlys 016/skulp i aftenlys 016.jpg'),
                thumb: require('./Parken_files/skulp i aftenlys 016/thumb.jpg')
            },
        ]
    },
]


export default media
