<template>
  <v-container
      class="ma-0 pa-0"
  >
    <h1>Galleri</h1>
    <PhotoAlbum
        class="pa-0 ma-0"
        v-for="album in albums"
        v-bind:key="album.id"
        v-bind:title="album.title"
        v-bind:media="album.assets"
    ></PhotoAlbum>

  </v-container>
</template>

<script>

import albums from '../assets/albums.js'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import PhotoAlbum from "@/components/PhotoAlbum";

export default {

  name: "Gallery",
  components: {
    PhotoAlbum,
  },

  data() {
    return {
      albums,
      rotation: {
        x: 0,
        y: 0,
        z: 0
      },
      lights: [
        {
          type: 'HemisphereLight',
          position: {x: 0, y: 1, z: 0},
          skyColor: 0xaaaaff,
          groundColor: 0x806060,
          intensity: 0.6,
        },
        {
          type: 'HemisphereLight',
          position: {x: 0, y: 1, z: 1},
          skyColor: 0xaaaaff,
          groundColor: 0x806060,
          intensity: 0.6,
        },
        {
          type: 'DirectionalLight',
          position: {x: 1, y: 1, z: 1},
          color: 0xffffff,
          intensity: 0.8,
        },
      ]
    }
  },

  methods: {
    onLoad() {
      this.rotate();
    },
    rotate() {
      this.rotation.y += 0.01;
      requestAnimationFrame(this.rotate);
    }
  }
}
</script>


<style scoped>

</style>